"use client";

import { GetHomeQuery } from "@/graphql/schema";
import { serializer } from "@/utils/rich-text-renderer";
import { RichText } from "@graphcms/rich-text-react-renderer";
import Image from "next/image";
import Link from "next/link";
import React, { useRef } from "react";
import { Icons } from "@/components/icons";

export default function About({ homes }: GetHomeQuery) {
  const boldIndexRef = useRef(0);

  // Define specific class names for each bold instance
  const boldStyles = [
    "no-strip-1", // Style for the first bold instance
    "no-strip-2", // Style for the second bold instance
    "no-strip-3", // Style for the third bold instance
    // Add more styles as needed
  ];

  return (
    <div className="overflow-hidden pb-12 md:pb-24">
      <section className="relative bg-gradient-to-b from-[#efefef] to-white py-16 md:py-36">
        <div className="cs-container relative">
          <h2 className="mx-auto max-w-3xl text-center text-5xl md:text-6xl">{homes[0]?.aboutTitle}</h2>
          <div className="flex py-10">
            <RichText
              content={homes[0]?.aboutSubtitle?.raw}
              renderers={{
                p: ({ children }) => (
                  <p className="mx-auto grid items-center gap-2 text-nowrap text-center md:flex md:gap-6">{children}</p>
                ),
                bold: ({ children }) => {
                  // Get the class based on the current index and increment it
                  const styleClass = boldStyles[boldIndexRef.current % boldStyles.length];
                  boldIndexRef.current += 1; // Increment index for next bold instance

                  return (
                    <span className={`button-long relative`}>
                      <span className={`${styleClass}`} />
                      {children}
                    </span>
                  );
                },
              }}
            />
          </div>
          <div className="relative z-10 mx-auto max-w-2xl py-6 text-center">
            <RichText content={homes[0]?.aboutContent?.raw} renderers={serializer} />
            <Link
              href={homes[0].aboutLink?.externalUrl || ""}
              className="relative mt-10 flex flex-col items-center gap-8 md:gap-16 lg:hidden"
            >
              <div className="button-long relative !w-max">
                <div className="strip" />
                {homes[0].aboutLink?.displayText}
              </div>
            </Link>
          </div>
          <Image
            src={homes[0].aboutImage?.url || ""}
            alt={homes[0].aboutImage?.altText || ""}
            width={500}
            height={500}
            className="mx-auto h-[175px] w-[175px] object-cover pl-6 pt-16 sm:h-[300px] sm:w-[300px] sm:pl-14"
          />
          <div
            /* href={homes[0].aboutLink?.externalUrl || ""} */
            className="relative mx-auto hidden w-max flex-col items-center gap-8 md:gap-16 lg:flex"
          >
            <div className="button-long relative w-max">
              <div className="no-strip" />
              {homes[0].aboutLink?.displayText}
            </div>
          </div>
          <div className="mb-24 pb-12 sm:mb-36 sm:pb-24 lg:mb-0 lg:pb-0">
            <div className="absolute -bottom-36 left-0 m-auto lg:-bottom-24 lg:-left-12 2xl:-left-36">
              <div className="relative h-full w-full">
                <div className="absolute -bottom-20 -left-20 top-0 -z-0 m-auto h-[220px] w-[220px] rounded-full bg-[#E5E5E5] md:-bottom-72 md:h-[300px] md:w-[300px] lg:-bottom-52 2xl:-left-[30%] 2xl:h-[500px] 2xl:w-[500px]" />
                <Image
                  src={homes[0].people[0].image?.url || ""}
                  alt={homes[0].aboutImage?.altText || ""}
                  width={600}
                  height={1000}
                  className="relative -left-16 bottom-20 z-0 h-[315px] w-auto object-cover md:-left-24 md:bottom-8 md:h-[550px] lg:bottom-24 lg:max-w-[300px] 2xl:h-[625px]"
                />
                <p className="relative bottom-16 left-4 w-max text-xs font-bold md:bottom-10 md:text-sm lg:bottom-24 lg:left-16">
                  {homes[0].people[0].name}
                </p>
                <p className="relative bottom-16 left-4 w-max text-xs md:bottom-10 md:text-sm lg:bottom-24 lg:left-16">
                  {homes[0].people[0].role}
                </p>
              </div>
            </div>
            <div className="absolute -bottom-28 right-0 m-auto lg:-bottom-24 lg:-right-12 2xl:-right-36">
              <div className="relative h-full w-full">
                <div className="absolute -bottom-32 -right-16 top-0 -z-0 m-auto h-[200px] w-[200px] rounded-full bg-[#E5E5E5] md:-bottom-72 md:h-[300px] md:w-[300px] lg:-bottom-52 2xl:-right-[30%] 2xl:h-[500px] 2xl:w-[500px]" />
                <Image
                  src={homes[0].people[1].image?.url || ""}
                  alt={homes[0].aboutImage?.altText || ""}
                  width={600}
                  height={1000}
                  className="relative -right-4 bottom-16 z-0 h-[315px] w-auto object-cover md:bottom-8 md:h-[550px] md:max-w-[300px] lg:bottom-24 2xl:h-[625px]"
                />
                <p className="relative -right-10 bottom-16 w-max text-xs font-bold md:-right-24 md:bottom-10 md:text-sm lg:-right-20 lg:bottom-24">
                  {homes[0].people[1].name}
                </p>
                <p className="relative -right-10 bottom-16 w-max text-xs md:-right-24 md:bottom-10 md:text-sm lg:-right-20 lg:bottom-24">
                  {homes[0].people[1].role}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mx-auto hidden max-w-lg grid-cols-2 gap-10 lg:grid">
        <Icons.arrowUp className="ml-auto" />
        <Icons.arrowUp className="-scale-x-100" />
      </div>
      {/* <Image src="/about-desktop.png" alt="About" width={1200} height={800} className="" /> */}
      <div className="cs-container grid justify-between gap-16 pt-12 md:flex">
        <div className="flex max-w-md flex-col items-start gap-5 text-left">
          <Image
            src={homes[0].aboutLeft?.image?.url || ""}
            alt={homes[0].aboutLeft?.image?.altText || ""}
            width={600}
            height={300}
            className="h-[85px] object-contain object-left"
          />
          <RichText content={homes[0]?.aboutLeft?.content?.raw} renderers={serializer} />
          <Link
            href={homes[0].aboutLeft?.url || ""}
            target="_blank"
            className="relative flex w-max flex-col gap-8 md:gap-16"
          >
            <div className="button-long relative w-max">
              <div className="strip-1" />
              {homes[0].aboutLeft?.slugText}
            </div>
          </Link>
        </div>
        <div className="flex max-w-md flex-col gap-5 md:items-end md:text-right">
          <Image
            src={homes[0].aboutRight?.image?.url || ""}
            alt={homes[0].aboutRight?.image?.altText || ""}
            width={600}
            height={300}
            className="h-[85px] object-contain object-left md:object-right"
          />
          <RichText content={homes[0]?.aboutRight?.content?.raw} renderers={serializer} />
          <Link
            href={homes[0].aboutRight?.url || ""}
            target="_blank"
            className="relative flex w-max flex-col gap-8 md:gap-16"
          >
            <div className="button-long relative w-max">
              <div className="strip-2" />
              {homes[0].aboutRight?.slugText}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
