"use client";

import { RichText } from "@graphcms/rich-text-react-renderer";
import { serializer } from "@/utils/rich-text-renderer";
import { GetHomeQuery } from "@/graphql/schema";
import Image from "next/image";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
import Link from "next/link";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/* interface IntroProps {
  intro: GetHomeQuery;
} */

export default function Intro({ intro }: any) {
  // TODO: Fix type
  const home = intro;
  const imageRef = useRef<HTMLImageElement>(null);

  /* useGSAP(() => {
    gsap.to(imageRef.current, {
      duration: 1,
      ease: "power4.inOut",
      scrollTrigger: {
        trigger: home.heroImage?.url || "",
        start: "top top",
        end: "bottom bottom",
        scrub: true,
      },
    });
  }); */

  return (
    <section className="cs-container">
      <div className="flex flex-col bg-white py-12 md:py-24 lg:items-center">
        <h1 className="mb-6 font-extrabold">{home.title}</h1>
        <h2 className="mb-16 text-3xl font-normal">{home.subtitle}</h2>
        {/* Image Section */}
        <div className="relative flex h-[450px] w-full items-center">
          <Icons.mirrorLine className="absolute left-3 top-0 z-10 h-full w-auto text-primary md:left-0" />
          <Image
            src={home.heroImage?.url || ""}
            alt="Car Repair Inspection"
            priority
            width={1920}
            height={1080}
            className="ml-[45px] !h-[300px] !w-[-webkit-fill-available] object-cover md:ml-[35px] md:max-w-[1200px]"
            style={{ clipPath: "polygon(126px 0%, 100% 0%, 100% 100%, 0% 100%)" }}
          />
        </div>

        {/* Text Section */}
        <div className="mt-16 max-w-4xl px-4 text-center">
          <RichText content={home.introContent?.raw} renderers={serializer} />
        </div>

        {/* Buttons Section */}
        <div className="mt-16 grid justify-center gap-4 md:flex lg:gap-16">
          <div
            /* href={home.introBoxes[0].slug || ""} */ className="relative flex flex-col items-center gap-8 md:gap-16"
          >
            <div className="button-long relative">
              <div className="no-strip" />
              Schadenaufnahme per App
              {/* <p className="absolute inset-0 m-auto flex items-center justify-center text-white">
            </p> */}
            </div>
            <img src={home.introBoxes[0].image?.url} alt="Mobile device" className="h-[150px]" />
          </div>
          <div
            /* href={home.introBoxes[0].slug || ""} */ className="relative flex flex-col items-center gap-8 md:gap-16"
          >
            <div className="button-long relative">
              <div className="no-strip" />
              CombiPlus Online
              {/* <p className="absolute inset-0 m-auto flex items-center justify-center text-white">
            </p> */}
            </div>
            <img src={home.introBoxes[1].image?.url} alt="Mobile device" className="h-[175px]" />
          </div>
          <div
            /* href={home.introBoxes[0].slug || ""} */ className="relative flex flex-col items-center gap-8 md:gap-16"
          >
            <div className="button-long relative">
              <div className="no-strip" />
              myConnect Massenschadenportal
              {/* <p className="absolute inset-0 m-auto flex items-center justify-center text-white">
            </p> */}
            </div>
            <img src={home.introBoxes[2].image?.url} alt="Mobile device" className="h-[150px]" />
          </div>
        </div>
      </div>
    </section>
  );
}
