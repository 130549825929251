"use client";

import { Icons } from "@/components/icons";
import { GetHomeQuery } from "@/graphql/schema";
import Image from "next/image";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { toast } from "@/hooks/use-toast";
import { useState } from "react";

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

const FormSchema = z.object({
  firstName: z.string().min(2, "Bitte geben Sie einen gültigen Vornamen ein."),
  lastName: z.string().min(2, "Bitte geben Sie einen gültigen Nachnamen ein."),
  email: z.string().email("Bitte geben Sie eine gültige E-Mail-Adresse ein."),
  phone: z
    .string()
    .refine((val) => phoneRegex.test(val), { message: "Bitte geben Sie eine gültige Telefonnummer ein." }),
  topic: z.string().min(1, "Bitte wählen Sie ein Anliegen aus."),
  acceptTerms: z.literal(true, {
    errorMap: () => ({ message: "Bitte akzeptieren Sie die Datenschutzvereinbarung." }),
  }),
});

type FormData = z.infer<typeof FormSchema>;

export default function Form({ homes }: GetHomeQuery) {
  const [isSending, setIsSending] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({ resolver: zodResolver(FormSchema) });

  const onSubmit = async (data: FormData, e: any) => {
    if (isSending || !canSubmit) return;

    setIsSending(true);
    setCanSubmit(false);

    try {
      const formData = { ...data };
      const res = await fetch("/api/email", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "content-type": "application/json",
        },
      });

      // Handle the error
      if (!res.ok) {
        throw new Error(`Fehler beim Senden: ${await res.text()}`);
      }

      reset();

      toast({
        title: "Ihre Nachricht wurde erfolgreich gesendet.",
        description: "Wir werden uns so schnell wie möglich bei Ihnen melden.",
        duration: 5000,
        style: {
          background: "hsl(var(--success))",
          color: "hsl(var(--primary-foreground))",
        },
      });
    } catch (error: unknown) {
      toast({
        title: "Beim Senden der Nachricht ist ein Fehler aufgetreten.",
        description: "Bitte versuchen Sie es später noch einmal.",
        duration: 5000,
        style: {
          background: "hsl(var(--destructive))",
          color: "hsl(var(--destructive-foreground))",
        },
      });
    } finally {
      setIsSending(false);
      setCanSubmit(true);
    }
  };

  return (
    <div className="cs-container flex flex-col items-center px-4 pb-12 md:pb-24">
      <h2 className="mb-4 text-center text-6xl font-bold">Jetzt beraten lassen.</h2>

      {/* Image */}
      <div className="relative flex h-[450px] w-full items-center">
        <Icons.mirrorLine className="absolute right-3 top-0 z-10 h-full w-auto text-primary md:right-0" />
        <Image
          src={homes[0].contact?.image?.url || ""}
          alt="Car Repair Inspection"
          priority
          width={1500}
          height={1000}
          className="mr-[45px] !h-[300px] !w-[-webkit-fill-available] object-cover md:mr-[35px] md:max-w-[1200px]"
          style={{ clipPath: "polygon(0px 0px, 100% 0px, calc(100% - 126px) 100%, 0px 100%)" }}
        />
      </div>

      <p className="mb-16 mt-12 text-center text-3xl font-semibold text-[#3E4342]">
        Ob Einzelfall oder Massenschaden –<br />
        Wir haben das richtige Tool für Sie
      </p>

      {/* Form */}
      <form onSubmit={handleSubmit(onSubmit)} className="flex w-full flex-col gap-y-4">
        {/* First Name and Last Name */}
        <div className="grid gap-4 md:flex">
          <div className="flex-1">
            <input
              type="text"
              {...register("firstName")}
              placeholder="Max"
              className={`w-full bg-[#EFEFEF] px-10 py-4 placeholder:text-[#C8C8C8] focus:outline-none ${
                errors.firstName ? "bg-red-200 placeholder:text-[#7e7e7e]" : ""
              }`}
              style={{ clipPath: "polygon(2em 0, 100% 0%, calc(100% - 2em) 100%, 0% 100%)" }}
            />
            <label className="text-sm text-[#3E4342]">Vorname*</label>
            {errors.firstName && <p className="text-red-500">Bitte geben Sie einen Vornamen ein.</p>}
          </div>
          <div className="flex-1">
            <input
              type="text"
              {...register("lastName")}
              placeholder="Mustermann"
              className={`w-full bg-[#EFEFEF] px-10 py-4 placeholder:text-[#C8C8C8] focus:outline-none ${
                errors.lastName ? "bg-red-200 text-red-500 placeholder:text-[#7e7e7e]" : ""
              }`}
              style={{ clipPath: "polygon(2em 0, 100% 0%, calc(100% - 2em) 100%, 0% 100%)" }}
            />
            <label className="text-sm text-[#3E4342]">Nachname*</label>
            {errors.lastName && <p className="text-red-500">Bitte geben Sie Ihren Nachnamen ein.</p>}
          </div>
        </div>

        {/* Email */}
        <div>
          <input
            type="email"
            {...register("email")}
            placeholder="max.mustermann@musterfirma.de"
            className={`w-full bg-[#EFEFEF] px-10 py-4 placeholder:text-[#C8C8C8] focus:outline-none ${
              errors.email ? "bg-red-200 placeholder:text-[#7e7e7e]" : ""
            }`}
            style={{ clipPath: "polygon(2em 0, 100% 0%, calc(100% - 2em) 100%, 0% 100%)" }}
          />
          <label className="text-sm text-[#3E4342]">E-Mail*</label>
          {errors.email && <p className="text-red-500">Bitte geben Sie eine gültige E-Mail-Adresse ein.</p>}
        </div>

        {/* Phone */}
        <div className="grid gap-4 md:flex">
          <div className="flex-1">
            <input
              type="tel"
              {...register("phone")}
              placeholder="+49 12345 6789"
              className={`w-full bg-[#EFEFEF] px-10 py-4 placeholder:text-[#C8C8C8] focus:outline-none ${
                errors.phone ? "bg-red-200 placeholder:text-[#7e7e7e]" : ""
              }`}
              style={{ clipPath: "polygon(2em 0, 100% 0%, calc(100% - 2em) 100%, 0% 100%)" }}
            />
            <label className="text-sm text-[#3E4342]">Telefon*</label>
            {errors.phone && <p className="text-red-500">Bitte geben Sie eine gültige Telefonnummer ein.</p>}
          </div>
          <div className="flex-1">
            <select
              {...register("topic")}
              defaultValue=""
              className={`h-[calc(1.5em_+_32px)] w-full bg-[#EFEFEF] px-10 py-4 placeholder:text-[#C8C8C8] focus:outline-none ${
                errors.topic ? "bg-red-200 placeholder:text-[#7e7e7e]" : ""
              }`}
              style={{
                clipPath: "polygon(2em 0, 100% 0%, calc(100% - 2em) 100%, 0% 100%)",
                color: watch("topic") ? "#000000" : errors.topic ? "#999999" : "#C8C8C8",
              }}
            >
              <option value="" disabled>
                Bitte auswählen
              </option>
              <option value="Einzelfall">Einzelfall</option>
              <option value="Massenschaden">Massenschaden</option>
            </select>
            <label className="text-sm text-[#3E4342]">Anliegen</label>
            {errors.topic && <p className="text-red-500">Bitte wählen Sie ein Anliegen aus.</p>}
          </div>
        </div>

        {/* Checkbox and Submit Button */}
        <div className="mt-4 items-center justify-between md:flex">
          <div className="relative">
            <label className="flex items-center text-sm text-gray-600">
              <input
                type="checkbox"
                className="form-checkbox text-pink-500"
                {...register("acceptTerms", { required: true })}
              />
              <span className="ml-2">
                Ich akzeptiere Ihre{" "}
                <a href="/datenschutz" target="_blank" className="text-pink-500 underline">
                  Datenschutzvereinbarung
                </a>
              </span>
            </label>
            {errors.acceptTerms && <p className="text-red-500">Bitte akzeptieren Sie die Datenschutzvereinbarung.</p>}
          </div>
          <button type="submit" className="mt-8">
            <div className="button-long relative !w-max">
              <div className="strip" />
              <span className="relative z-10">Senden</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  );
}
